export {};

declare global {
  interface Date {
    expired(): boolean;
  }
}

Date.prototype.expired = function (): boolean {
    return this.getTime() < new Date().getTime();
}