<ng-template class="theme-modal" #documentPreview let-modal>
    <div class="modal-content quick-view-modal">
      <div class="modal-body">
        <button type="button" class="close float-end border-0" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="row" *ngIf="documnetDetails.length">
          <div class="col-sm-12">
            <table class="table cart-table table-responsive-xs">
              <thead>
                <tr class="table-head">
                  <th scope="col">immagine</th>
                  <th scope="col">id prodotto</th>
                  <th scope="col">descrizione</th>
                  <th scope="col">read</th>
                  <th scope="col">expected</th>
                  <th scope="col">moved</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let documnetDetail of documnetDetails | slice: (page - 1) * pageSize : page * pageSize">
                  <td>
                    <a [routerLink]="['/shop/product/', documnetDetail.reference.replace(' ', '-')]" (click)="modal.dismiss('Product Select')">
                      <i *ngIf="!documnetDetail.imageUrl" class="ti-image"></i>
                      <img *ngIf="documnetDetail.imageUrl" [src]="documnetDetail.imageUrl" [alt]="documnetDetail.shortDescription" />
                    </a>
                  </td>
                  <td>
                    <div>{{ documnetDetail.shortDescription }}</div>
                    <div>{{ documnetDetail.reference }}</div>
                  </td>
                  <td>
                    <div class="text-dark">{{ documnetDetail.longDescription }}</div>
                  </td>
                  <td>
                    <div class="text-dark">{{ documnetDetail.read }}</div>
                  </td>
                  <td>
                    <div class="text-dark">{{ documnetDetail.moved }}</div>
                  </td>
                  <td>
                    <div class="text-dark">{{ documnetDetail.expected }}</div>
                  </td>
                  
                </tr>
              </tbody>
            </table>
            <nav aria-label="Page navigation" class="d-flex justify-content-center mt-4">
              <ul class="pagination">
                <li class="page-item" [class.disabled]="page === 1">
                  <a class="page-link" (click)="changePage(page - 1)" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li class="page-item" *ngFor="let pageNum of getPaginationNumbers()" [class.active]="page === pageNum" [class.disabled]="pageNum === '...'">
                  <a class="page-link" *ngIf="pageNum !== '...'" (click)="changePage(pageNum)">{{ pageNum }}</a>
                  <span class="page-link" *ngIf="pageNum === '...'">{{ pageNum }}</span>
                </li>
                <li class="page-item" [class.disabled]="page === totalPages">
                  <a class="page-link" (click)="changePage(page + 1)" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
</ng-template>
