import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Product } from '../../classes/product';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss', '../../../shop/collection/widgets/widgets-common.scss']
})
export class CategoriesComponent implements OnInit {
  @Input() categorySelect: string = '';
  @Output() categoryFilter: EventEmitter<any> = new EventEmitter<any>();

  public products: Product[] = [];
  public collapse: boolean = false;
  filterbyCategory: any;

  constructor(public productService: ProductService) {
    this.productService.getProducts.subscribe(product => {
      this.products = product;
      this.filterbyCategory = this._filterbyCategory;
    });
  }

  ngOnInit(): void {
  }

  get _filterbyCategory() {
    const category = [...new Set(this.products.map(product => product.rfidClass))]
    return category
  }
  onClick(category: string){
    this.categoryFilter.emit({rfidClass: category});
    this.collapse = false;
  }
  onMouseEnter(){
    this.collapse = true;
  }
  onMouseOut(){
    this.collapse = false;
  }

}
