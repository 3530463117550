import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenStorageService } from '../auth/token-storage.service';
const TOKEN_HEADER_KEY = 'Authorization';       // for Spring Boot back-end

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private tokenStorage: TokenStorageService) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let token = this.tokenStorage.getToken();
        if (token) {

            let h = {
              headers: request.headers.set(TOKEN_HEADER_KEY, `Bearer ${token}`)
            }


            const headers = new HttpHeaders({
              'Authorization': `Bearer ${token}`,
              'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
              'Access-Control-Allow-Origin': '*'
            });

            request = request.clone( { headers } );
        }
        return next.handle(request);
    }
}
