<div class="img-wrapper">
  <div class="lable-block">
    <span class="lable3" *ngIf="product.new">new</span>
  </div>
  <div class="front">
    <a [routerLink]="['/shop/product/', product.title.replace(' ', '-')]">
      <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" [lazyLoad]="ImageSrc ? ImageSrc : product.images[0].src" class="img-fluid lazy-loading" alt="{{ product?.images[0].alt }}" />
    </a>
  </div>
  <div class="back" *ngIf="onHowerChangeImage && product.images.length > 1">
    <a [routerLink]="['/shop/product/', product.title.replace(' ', '-')]">
      <img [src]="ImageSrc ? ImageSrc : product.images[1].src" class="img-fluid lazy-loading" alt="{{ product?.images[1].alt }}" />
    </a>
  </div>
  <ul class="product-thumb-list" *ngIf="thumbnail">
    <li class="grid_thumb_img" [class.active]="ImageSrc == image.src" *ngFor="let image of product.images">
      <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image.src)">
        <img [lazyLoad]="image.src" />
      </a>
    </li>
  </ul>
  <div class="cart-detail">
    <a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product)">
      <i class="ti-heart" aria-hidden="true"></i>
    </a>
    <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
      <i class="ti-search" aria-hidden="true"></i>
    </a>
    <a href="javascript:void(0)" title="Compare" (click)="addToCompare(product)">
      <i class="ti-reload" aria-hidden="true"></i>
    </a>
  </div>
</div>
<div class="product-info">
  <div>
    <div class="prod-description">{{product.description}}</div>
    <div class="prod-id">{{product?.title}}</div>
    <div class="prod-material">{{product.rfidMaterial}}</div>
    <div class="prod-season">{{season(product.rfidSeason, 's y')}}</div>
    <div class="prod-avaible" [ngClass]="{'prod-avaible-true': product.stock > 0}"></div>

    <div class="add-btn">
      <a href="javascript:void(0)" class="btn btn-outline" title="Add to cart" (click)="CartModal.openModal(product)" *ngIf="cartModal"> <i class="ti-shopping-cart"></i></a>
      <a href="javascript:void(0)" class="btn btn-outline" title="Add to cart" (click)="addToCart(product)" *ngIf="!cartModal"> <i class="ti-shopping-cart"></i></a>
      <button type="button" class="btn btn-solid" (click)="addToWishlist(product)"><i class="fa fa-heart"></i></button>
    </div>
  </div>
</div>

<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>
