import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input, ElementRef, Inject, AfterViewInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Product } from '../../../classes/product';
import { ProductService } from '../../../../shared/services/product.service';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss']
})
export class ImagePreviewComponent implements OnInit, OnDestroy {
  @Input() product: Product;
  @ViewChild("imagePreview", { static: false }) ImagePreview: TemplateRef<any>;

  public closeResult: string;
  public ImageSrc: string;
  public counter: number = 1;
  public modalOpen: boolean = false;
  public imageIndex = 0;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              private router: Router, private modalService: NgbModal,
              public productService: ProductService, private renderer: Renderer2, private el: ElementRef) { }

  ngOnInit(): void {
    console.log();
  }

  openModal() {
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR
      this.modalService.open(this.ImagePreview, {
        size: 'xl',
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        windowClass: 'Imagepreview'
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }
  nextImage(){
    if(this.product.images.length-1 == this.imageIndex)
      this.imageIndex = 0;
    else
      this.imageIndex++;
  }
  previewImage(){
    if(this.imageIndex == 0)
      this.imageIndex = this.product.images.length-1;
    else
      this.imageIndex--;
  }
}
