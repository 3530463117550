<div class="navbar" [ngStyle]="{ 'padding-top.px': (height - 25.5) / 2, 'padding-bottom.px': (height - 25.5) / 2 }">
  <a href="javascript:void(0)" (click)="leftMenuToggle()">
    <div class="bar-style"><i class="fa fa-bars sidebar-bar" aria-hidden="true"></i></div>
  </a>
  <div id="mySidenav" class="sidenav" [class.openSide]="navServices?.leftMenuToggle">
    <a href="javascript:void(0)" class="sidebar-overlay" (click)="leftMenuToggle()" (mouseover)="onHover(false)"></a>
    <nav id="unset">
      <div (click)="leftMenuToggle()">
        <div class="sidebar-back text-start"><i class="fa fa-angle-left pe-2" aria-hidden="true"></i> Back</div>
      </div>
      <h5>Advanced Search</h5>
      <ul id="sub-menu" class="sidebar-menu">
        <li class="menu-item mt-2" *ngFor="let paramConst of parametersConst">
          <div class="menu-item-content">
            <label for="category-select" class="menu-label">{{ paramConst[1] }}</label>
            <!-- prettier-ignore -->
            <ng-select
              [items]="parameterList[paramConst[0]]"
              bindLabel="name"
              [addTag]="false"
              [multiple]="paramConst[2]"
              [hideSelected]="true"
              [minTermLength]="2"
              typeToSearchText="Please enter 2 or more characters"
              [(ngModel)]="parametersValue[paramConst[0]]"
              (change)="changeEvent()"
              >
            </ng-select>

            <!-- <select [(ngModel)]="parametersValue[paramConst[0]]" class="form-control menu-select input-sm" (change)="filterProduct()">
              <option value="">-- Select --</option>
              <option *ngFor="let item of parameterList[paramConst[0]]">{{item}}</option>
            </select> -->
          </div>
        </li>
      </ul>
      <div class="filterSend mt-3">
        <a class="btn btn-outline btn-block" (click)="applyFilter()">Filter</a>
      </div>
    </nav>
  </div>
</div>
