import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Product } from '../../classes/product';
import { ProductService } from '../../services/product.service';
import { customerLogos } from '../../data/customerOptions';

@Component({
  selector: 'app-brands-filter',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss', '../../../shop/collection/widgets/widgets-common.scss']
})
export class BrandsComponent implements OnInit {
  @Input() brandSelect: string = '';
  @Output() categoryFilter: EventEmitter<any> = new EventEmitter<any>();

  public products: Product[] = [];
  public collapse: boolean = false;
  brands: any;


  constructor(public productService: ProductService) {

    this.productService.getProducts.subscribe(product => {
      this.products = product;
      this.brands = this._filterbyBrand;
    });

    //this.brands = customerLogos.map(item => item.text);
  }

  get _filterbyBrand() {

    const uniqueBrands = new Set<any>();
    this.products.forEach((product) => {

      if(product.extraFields) {
        product.extraFields.forEach((field) => {
          if (field.key === 'brand') {
            uniqueBrands.add(field.value);
          }
        });
      }
    });

    const brands = Array.from(uniqueBrands).sort((a, b) => a.localeCompare(b));
    return brands
  }

  ngOnInit(): void {
  }

  onClick(brand: string){
    this.categoryFilter.emit({searchText: brand});
    this.collapse = false;
  }
  onMouseEnter(){
    this.collapse = true;
  }
  onMouseOut(){
    this.collapse = false;
  }

}
