import { Component, OnInit, Injectable, PLATFORM_ID, Inject, Input } from '@angular/core';
import { ViewportScroller, isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { ProductService } from "../../services/product.service";
import { Product } from "../../classes/product";
import { Trie, WildcardSearch } from '../../utils/WildcardSearch';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  @Input() height
  textHeight = 24;
  public products: Product[] = [];
  public search: boolean = false;
  public searchProductText: string = '';

  public languages = [{
    name: 'English',
    code: 'en'
  }, {
    name: 'French',
    code: 'fr'
  }];

  public currencies = [{
    name: 'Euro',
    currency: 'EUR',
    price: 0.90 // price of euro
  }, {
    name: 'Rupees',
    currency: 'INR',
    price: 70.93 // price of inr
  }, {
    name: 'Pound',
    currency: 'GBP',
    price: 0.78 // price of euro
  }, {
    name: 'Dollar',
    currency: 'USD',
    price: 1 // price of usd
  }]

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private viewScroller: ViewportScroller,
    public productService: ProductService) {
    this.productService.cartItems.subscribe(response => this.products = response);

  }

  ngOnInit(): void {
  }

  searchToggle(){
    this.search = !this.search;
  }

  changeLanguage(code){
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code)
    }
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  get getTotalItems(): Observable<number> {
    return this.productService.cartTotalItems();
  }

  removeItem(product: any) {
    this.productService.removeCartItem(product);
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency
  }

  onSubmit(form: any) {
    var tags:any = {
      searchText: this.searchProductText
    }
    tags.page = null; // Reset Pagination
    console.log(this.route)
    this.router
      .navigate(['/shop'], {
        queryParams: tags,
        queryParamsHandling: "", // preserve the existing query params in the route
        skipLocationChange: false, // do trigger navigation
      })
      .finally(() => {
        this.viewScroller.setOffset([120, 120]);
        this.viewScroller.scrollToAnchor("products"); // Anchore Link
        this.searchToggle();
      });


    const pattern = this.searchProductText;

    // Esegui la ricerca usando Wildcard
    const matches = WildcardSearch(this.products, pattern);
  }

}
