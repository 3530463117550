export {};

declare global {
  interface Number {
    toCurrency(): string;
    toCurrencySymbol(): string;
    toNumber(): number;
    alwaysGT0(): number;
    toNumberMoney(): number;
    toInt(): number;
    fromFiscalPrinter(): number;
    getIvaFromTotal(): number;
  }
}

Number.prototype.toNumber = function (): number {
  return this;
};
Number.prototype.toNumberMoney = function (): number {
  return Math.round(this*100)/100;
}
Number.prototype.toCurrency = function (data: { lang: string; currency: string } | any = {}): string {
  var d = {
    lang: 'it-IT',
    currency: '',
  };
  if (data != null) {
    if (data.lang) {
      switch (data.lang) {
        case 'it':
        case 'it-IT':
          d.lang = 'it-IT';
          break;
        default:
          d.lang = data.lang;
      }
    }
    if (data.currency) {
      switch (data.currency) {
        case '€':
        case 'eur':
          d.currency = 'eur';
          break;
        default:
          d.currency = data.currency;
      }
    }
  }
  return d.currency == ''
    ? this.toLocaleString(d.lang, {
        style: 'decimal',
        minimumFractionDigits: 2,
      })
    : this.toLocaleString(d.lang, {
        style: 'currency',
        currency: d.currency,
        minimumFractionDigits: 2,
      });
};
Number.prototype.toCurrencySymbol = function (data: { lang: string; currency: string } | any = {}): string {
    var d = data;
    if (!data.currency) 
        d.currency = 'eur';
    return this.toCurrency(d);
}
// always return grather or equal than 0
Number.prototype.alwaysGT0 = function(){
  return(this > 0)?this:0;
}
Number.prototype.toInt = function(){
  return parseInt(this);
}
Number.prototype.fromFiscalPrinter = function(){
  return this/10000;
}
Number.prototype.getIvaFromTotal = function(){
  return Math.round(this - this/1.22);
}
//   windowAny.formattedNumber = function (numberToFormat: number, lang: string = 'it', currency: string = ''): string {
//     if(lang == 'it' || lang == 'it-IT'){
//       lang = 'it-IT'
//     }
//     if(currency == '€')
//       currency = 'eur';

//     return currency == ''
//       ? numberToFormat.toLocaleString(lang, {
//           style: 'decimal',
//           minimumFractionDigits: 2,
//         })
//       : numberToFormat.toLocaleString(lang, {
//           style: 'currency',
//           currency: currency,
//           minimumFractionDigits: 2,
//         });
//   };
