// Products
export interface Product {
  id?: string;
  epc?: string;
  title?: string;
  description?: string;
  type?: string;
  brand?: string;
  collection?: any[];
  category?: string;
  rfidClass?: string;
  rfidEcoFriendly?: boolean;
  rfidGender?: string;
  rfidMaterial?: string;
  rfidSeason?: string;
  rfidSubclass?: any;
  price?: number;
  sale?: boolean;
  discount?: number;
  stock?: number;
  new?: boolean;
  quantity?: number;
  tags?: any[];
  variants?: Variants[];
  images?: Images[];
  extraFields?: ExtraField[];
}

export interface Variants {
  variant_id?: number;
  id?: number;
  sku?: string;
  size?: string;
  colour?: string;
  image_id?: number;
}

export interface Images {
  image_id?: number;
  id?: number;
  alt?: string;
  src?: string;
  variant_id?: any[];
}

export interface ExtraField {
  key: string;
  value: string;
}

export interface SeasonCollection{
  year: string;
  season: string;
}

export function season(season: string, out:string = null): SeasonCollection | string{
  if(!season || season == '')return null;
  const s: SeasonCollection = {
    year: season.substring(0, 4),
    season: (season.substring(4)=='1')?'P/E':'A/I',
  }
  if(out == null){
    return s;
  }
  else{
    return out.replace(/s|y/g, match => match === 's' ? s.season : s.year);
  }
}

// [name,label,multi]
export const parametersFilterConst: [string, string, boolean][] = [
  ['rfidGender', 'categoria', true],
  ['category', 'linea', true],
  ['rfidClass', 'classe', true],
  ['color', 'colore', true],
  ['rfidMaterial', 'materiale', true],
  ['year', 'anno', true],
  ['season', 'stagione', true],
  ['ecofriendly', "sostenibilita'", false],
];