import { Component, Input, OnInit } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from '../../services/product.service';
import { parametersFilterConst, season } from '../../classes/product';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
})
export class LeftMenuComponent implements OnInit {
  @Input() height;
  public menuItems: Menu[];
  public seasonFn = season;

  public all_products: any[] = [];
  public filteredProduct: any[] = [];
  public parameterList: any = {};
  public parametersConst = parametersFilterConst;
  public parametersValue = {};

  // prettier-ignore
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public navServices: NavService,
    private productService: ProductService,
    private viewScroller: ViewportScroller,
  ) {
    this.fetch();
  }

  ngOnInit(): void {}

  fetch(): void {
    this.route.queryParams.subscribe((params) => {
      if (this.productService._products == null) {
        // Get Filtered Products from DB..
        this.productService.getProducts.subscribe((response) => {
          this.productService._products = response;
          this.initFilter(response, params);
        });
      } else {
        this.initFilter(this.productService._products, params);
      }
    });

    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
  }

  initFilter(response, params) {
    this.all_products = response;
    this.filterProduct();
    for (let item of this.parametersConst) {
      this.getParameterList(item[0]);
      // switch (item[0]) {
      //   case 'color':
      //     if(params[item[0]]){
      //       this.parametersValue[item[0]] = params[item[0]].split(',');
      //     }
      //     else{
      //       this.parametersValue[item[0]] = '';
      //     }
      //     break;
      //   default:
      //     this.parametersValue[item[0]] = params[item[0]] ?? '';
      //     break;
      // }
      if(item[2]){
        if (params[item[0]]) {
          this.parametersValue[item[0]] = params[item[0]].split(',');
        } else {
          this.parametersValue[item[0]] = null;
        }
      }
      else{
        if (params[item[0]]) {
          this.parametersValue[item[0]] = params[item[0]];
        } else {
          this.parametersValue[item[0]] = null;
        }
      }
    }
    console.log(this.parameterList)
    console.log(this.parametersValue)
  }

  leftMenuToggle(): void {
    this.fetch();
    this.navServices.leftMenuToggle = !this.navServices.leftMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

  onHover(menuItem) {
    if (window.innerWidth > 1200 && menuItem) {
      document.getElementById('unset').classList.add('sidebar-unset');
    } else {
      document.getElementById('unset').classList.remove('sidebar-unset');
    }
  }

  filterProduct() {
    this.filteredProduct = this._filterProduct();
    // for (let item of this.parametersConst) {
    //   this.getParameterList(item[0]);
    // }
  }

  changeEvent(){
    this.filteredProduct = this._filterProduct();
    for (let item of this.parametersConst) {
      this.getParameterList(item[0]);
    }
  }

  _filterProduct(item = null) {
    var fp = this.all_products;
    for (let param in this.parametersValue) {
      if (item == null || param != item) {
        if (this.parametersValue[param] != '' && this.parametersValue[param] != null && this.parametersValue[param].length > 0) {
          switch (param) {
            case 'year':
              fp = fp.filter((item) => this.parametersValue[param].includes(this.seasonFn(item.rfidSeason, 'y')));
              break;
            case 'season':
              fp = fp.filter((item) => this.parametersValue[param].includes(this.seasonFn(item.rfidSeason, 's y')));
              break;
            case 'ecofriendly':
              fp = fp.filter((item) => (item.rfidEcoFriendly + '') == this.parametersValue[param]);
              break;
            case 'color':
              fp = fp.filter((item) => this.parametersValue[param].includes(item.variants[0]['colour']));
              break;
            default:
              fp = fp.filter((item) => this.parametersValue[param].includes(item[param]));
          }
        }
      }
      // if ((this.parametersValue[param] != '' && this.parametersValue[param] != null) && (item == null || param != item)) {
      //   switch (param) {
      //     case 'year':
      //       fp = fp.filter((item) => this.seasonFn(item.rfidSeason, 'y') === this.parametersValue[param]);
      //       break;
      //     case 'season':
      //       fp = fp.filter((item) => this.seasonFn(item.rfidSeason, 's y') === this.parametersValue[param]);
      //       break;
      //     case 'ecofriendly':
      //       fp = fp.filter((item) => item.rfidEcoFriendly == (this.parametersValue[param] == 'true'));
      //       break;
      //     case 'color':
      //       fp = fp.filter((item) => this.parametersValue[param].includes(item.variants[0]['colour']));
      //       break;
      //     default:
      //       fp = fp.filter((item) => item[param] === this.parametersValue[param]);
      //   }
      // }
    }
    return fp;
  }

  getParameterList(id) {
    var rfidGenderList;
    switch (id) {
      case 'year':
        rfidGenderList = [...new Set(this._filterProduct(id).map((product) => this.seasonFn(product.rfidSeason, 'y')))].filter((item) => item && item != '2099').sort((a, b) => (a < b ? -1 : 1));
        this.parameterList[id] = rfidGenderList;
        break;
      case 'season':
        rfidGenderList = [...new Set(this._filterProduct(id).map((product) => this.seasonFn(product.rfidSeason, 's y')))].filter((item: string) => item && item.split(' ')[1] != '2099').sort((a: string, b: string) => (a.split(' ')[1] < b.split(' ')[1] ? -1 : 1));
        this.parameterList[id] = rfidGenderList;
        break;
      case 'ecofriendly':
        rfidGenderList = [...new Set(this._filterProduct(id).map((product) => product.rfidEcoFriendly + ''))].filter((item) => item !== '' && item != null && item != 'undefined');
        this.parameterList[id] = rfidGenderList;
        break;
      case 'color':
        rfidGenderList = [...new Set(this._filterProduct(id).map((product) => product.variants[0]['colour']))].filter((item) => item !== '' && item != null);
        this.parameterList[id] = rfidGenderList;
        break;
      default:
        // Estrai i valori rfidGender e rimuovi i duplicati
        rfidGenderList = [...new Set(this._filterProduct(id).map((product) => product[id]))].filter((item) => item || false).sort((a, b) => (a < b ? -1 : 1));
        this.parameterList[id] = rfidGenderList;
    }
  }

  applyFilter() {
    var params = {};
    for (let item of this.parametersConst) {
      let p = this.parametersValue[item[0]];
      if (p != null) {
        if (typeof p == 'object') {
          if(p.length > 0)
            params[item[0]] = p.join(',');
        } else {
          p = p+'';
          if (p != '') params[item[0]] = p + '';
        }
      }
    }
    this.router
      .navigate(['/shop'], {
        queryParams: params,
        queryParamsHandling: '', // preserve the existing query params in the route
        skipLocationChange: false, // do trigger navigation
      })
      .finally(() => {
        this.viewScroller.setOffset([120, 120]);
        this.viewScroller.scrollToAnchor('products'); // Anchore Link
      });
    this.navServices.leftMenuToggle = !this.navServices.leftMenuToggle;
  }
}
