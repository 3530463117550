import { Component, OnInit, Input } from '@angular/core';
import { collectionCustomer } from '../../data/customerOptions';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {
  collection: any[] = [];

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = 'assets/images/icon/logo.png' // Default Logo
  @Input() newsletter: boolean = true; // Default True

  public today: number = Date.now();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {
    for(let c in collectionCustomer){
      this.collection.push({
        key: c,
        value: collectionCustomer[c]
      });
    }
  }

  ngOnInit(): void {
  }
  openCollection(key: string){
    const tags = {
      collection: key
    }
    this.router
      .navigate(['/shop'], {
        queryParams: tags,
        queryParamsHandling: "", // preserve the existing query params in the route
        skipLocationChange: false, // do trigger navigation
      })
      .finally(() => {
        
      });
  }
}
