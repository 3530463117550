export const collectionCustomer = {
    "LIUJO Baby/Honey": "Capi Liu Jo",
    "LES PLUMES DE LIUJO": "Capi Ricerca",
    "LIUJO PreCollection": "Disegni Originali",
    "LIUJO BOY": "Capi Licenza",
    "LIUJO Black": "Liu Jo ECS",
    "LIUJO BLUE DENIM": "Denim"
};

export const sliderCustomer = [{
    title: 'Collezione donna',
    subTitle: 'Liu Jo',
    image: 'assets/images/customer/homepage/slider/Hero-Banner-woman.jpg'
  }, {
    title: 'Collezione bambina',
    subTitle: 'Liu Jo',
    image: 'assets/images/customer/homepage/slider/Hero-Banner-kids.jpg'
  }, {
    title: 'Collezione uomo',
    subTitle: 'Liu Jo',
    image: 'assets/images/customer/homepage/slider/liu-jo-uomo.jpg'
  }]

export const customerLogos = [
  {
    image: 'assets/images/partner/blumarineLogo.jpg',
    text: 'blumarine'
  },
  {
    image: 'assets/images/partner/zaraLogo.jpg',
    text: 'zara'
  },
  {
    image: 'assets/images/partner/gucciLogo.jpg',
    text: 'gucci'
  },
  {
    image: 'assets/images/partner/moschinoLogo.jpg',
    text: 'moschino'
  },
  {
    image: 'assets/images/partner/intimissimiLogo.jpg',
    text: 'intimissimi'
  },
]