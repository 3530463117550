export {};

declare global {
  interface String {
    toNumber(): number;
    toCurrency(): string;
    toCurrencySymbol(): string;
    stringify(): string;
    toNumberMoney(): number;
    toInt(): number;
    colorReplace(): string;
  }
}

String.prototype.toNumber = function (): number {
    var amountString = this.replace(/[^\d.,-]/g, '');
    const itCurrency = /^(-)?\d{1,3}(.\d{3})*(,\d{1,2})?$/g; // 100.000.000,99
    const it2Currency = /^(-)?\d+(\,\d{1,2})?$/; // 100000000,99
    const enCurrency = /^(-)?\d+(\.\d{1,2})?$/; // 100000000.99
    if (itCurrency.test(amountString) || it2Currency.test(amountString)) {
        amountString = amountString.replace(/[.]/g, '');
    }
    var parsedAmount = parseFloat(amountString.replace(/[,]/g, '.'));
    return isNaN(parsedAmount) ? 0 : parsedAmount;
};
String.prototype.toNumberMoney = function (): number {
  return this.toNumber().toNumberMoney();
}
String.prototype.toCurrency = function (data: { lang: string; currency: string } | any = {}): string {
    return this.toNumber().toCurrency(data);
}
String.prototype.toCurrencySymbol = function (data: { lang: string; currency: string } | any = {}): string {
    return this.toNumber().toCurrencySymbol(data);
}
String.prototype.stringify = function (){
  return this;
}
String.prototype.toInt = function (){
  return this.toNumber().toInt();
}

String.prototype.colorReplace = function (){
  return this.replaceAll('.','').replaceAll('\'','').replaceAll('/','_').replaceAll(' ','_').toLowerCase();
}