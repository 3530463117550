<div id="cart_side" class="add_to_cart" [ngClass]="direction" [class.openSide]="productService?.OpenCart">
    <a href="javascript:void(0)" class="overlay" (click)="closeCart()"></a>
    <div class="cart-inner">
        <div class="cart_top">
            <h3>my cart</h3>
            <div class="close-cart" (click)="closeCart()">
                <a href="javascript:void(0)">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </a>
            </div>
        </div>
        <div class="cart_media" *ngIf="products.length">
            <ul class="cart_product">
                <li *ngFor="let product of products">
                    <div class="media">
                        <a [routerLink]="['/shop/product/', product.title.replace(' ', '-')]">
                            <img class="me-3" [src]="product.images[0].src" [alt]="product.images[0].alt">
                        </a>
                        <div class="media-body">
                            <a [routerLink]="['/shop/product/', product.title.replace(' ', '-')]">
                                <h6 class="color-dark">{{ product?.title }}</h6>

                            </a>
                            <h4>
                                <span>
                                  {{ product?.description | titlecase }}
                                </span>
                            </h4>
                        </div>
                    </div>
                    <div class="close-circle">
                        <a href="javascript:void(0)" (click)="removeItem(product)">
                            <i class="ti-trash" aria-hidden="true"></i>
                        </a>
                    </div>
                </li>
            </ul>
            <ul class="cart_total">
                <li>
                    <div class="total">
                        <h5>
                            tatal items :
                            <span class="text-black">{{ getTotalItems | async  }}</span>
                        </h5>
                    </div>
                </li>
                <li>
                    <div class="buttons">
                        <a [routerLink]="['/shop/cart']" class="btn btn-solid btn-xs view-cart">view cart</a>
                        <a [routerLink]="['/shop/checkout']" class="btn btn-solid btn-xs checkout">checkout</a>
                    </div>
                </li>
            </ul>
        </div>
        <div class="col-sm-12 empty-cart-cls text-start" *ngIf="!products.length">
            <h5 class="my-3">
              <strong>Your Cart is Empty</strong>
            </h5>
            <a [routerLink]="['/shop']" class="btn btn-solid">continue shopping</a>
        </div>
    </div>
</div>
