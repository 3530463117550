import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input, ElementRef, Inject, AfterViewInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Product } from '../../../classes/product';
import { ProductService } from '../../../../shared/services/product.service';
import { DocumentDetail, Document } from 'src/app/shared/classes/document';

@Component({
  selector: 'app-document-detail',
  templateUrl: './document-detail.component.html',
  styleUrls: ['./document-detail.component.scss'],
})
export class DocumentDetailComponent implements OnInit, OnDestroy {
  @Input() document: Document;
  @ViewChild('documentPreview', { static: false }) DocumentPreview: TemplateRef<any>;
  public documnetDetails: DocumentDetail[] = [];

  public closeResult: string;
  // public ImageSrc: string;
  // public counter: number = 1;
  public modalOpen: boolean = false;
  // public imageIndex = 0;
  page: number = 1;
  pageSize: number = 10;
  maxPageNumbers: number = 5;
  constructor(@Inject(PLATFORM_ID) private platformId: Object, private router: Router, private modalService: NgbModal, public productService: ProductService, private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit(): void {}

  openModal(document: Document) {
    this.modalOpen = true;
    this.document = document;
    this.productService.getDocumentDetails(document.id).subscribe({
      next: (ret: DocumentDetail[]) => {
        this.documnetDetails = ret;
        if (isPlatformBrowser(this.platformId)) {
          // For SSR
          this.modalService
            .open(this.DocumentPreview, {
              size: 'xl',
              ariaLabelledBy: 'modal-basic-title',
              centered: true,
              windowClass: 'DocumentPreview',
            })
            .result.then(
              (result) => {
                `Result ${result}`;
              },
              (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
              }
            );
        }
      },
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  changePage(newPage: number): void {
    if (newPage >= 1 && newPage <= this.totalPages) {
      this.page = newPage;
    }
  }
  getPaginationNumbers() {
    const half = Math.floor(this.maxPageNumbers / 2);
    let start = Math.max(this.page - half, 1);
    let end = Math.min(start + this.maxPageNumbers - 1, this.totalPages);

    if (end - start < this.maxPageNumbers) {
      start = Math.max(end - this.maxPageNumbers + 1, 1);
    }

    const pages = [];
    if (start > 1) {
      pages.push(1);
      if (start > 2) {
        pages.push('...');
      }
    }

    for (let i = start; i <= end; i++) {
      pages.push(i);
    }

    if (end < this.totalPages) {
      if (end < this.totalPages - 1) {
        pages.push('...');
      }
      pages.push(this.totalPages);
    }

    return pages;
  }

  get totalPages(): number {
    return Math.ceil(this.documnetDetails.length / this.pageSize);
  }

  ngOnDestroy() {
    // if (this.modalOpen) {
    //   this.modalService.dismissAll();
    // }
  }
  // nextImage(){
  //   if(this.product.images.length-1 == this.imageIndex)
  //     this.imageIndex = 0;
  //   else
  //     this.imageIndex++;
  // }
  // previewImage(){
  //   if(this.imageIndex == 0)
  //     this.imageIndex = this.product.images.length-1;
  //   else
  //     this.imageIndex--;
  // }
}
