// Change your API endpoint here
//export const API_ENDPOINT = 'http://localhost:10000/';
//export const WS_ENDPOINT = 'ws://localhost:10000/';

export const API_ENDPOINT = 'https://campionario.azurewebsites.net/smartrfidbe/';
export const WS_ENDPOINT = 'wss://campionario.azurewebsites.net/smartrfidbe/';

//ENABLE USER REGISTRATION (default true)
export const USER_CAN_REGISTER = true;
export const VERSION = 'Version 1.0.0';
export const CUSTOMER_ID = '8a3d1b0e-c1d5-4c51-9d27-325018f60451';
export const DEPARTMENT_ID = '1c58ac13-902f-4f13-8e2b-e02150f9300e';
export const LOCATION_ID = 'a79be3a8-e5a6-4302-8ef5-b228456d5044';

// Gucci
/*
export const ACTIVEDIRECTORIES = [
  { id: 'LOCAL', name: 'Local Active Directory'},
  { id: 'OKTA', name: 'Kering OKTA Active Directory'}
]
*/

// Others
export const ACTIVEDIRECTORIES = [{ id: 'LOCAL', name: 'Local Active Directory' }];
