<div *ngIf="!loader">
  <div class="img-wrapper">
    <div class="lable-block">
      <span class="lable3" *ngIf="product.new">new</span>
      <span class="lable4" *ngIf="product.sale">on sale</span>
    </div>
    <div class="front">

      <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">
        <img 
          [defaultImage]="product.images ? product.images[0].src : 'assets/images/product/placeholder.jpg'" 
          [lazyLoad]="product.images ? product.images[0].src : 'assets/images/product/placeholder.jpg'" 
          class="img-fluid lazy-loading" 
          alt="{{ product.title }}" />
      </a>
    </div>
    <div class="back" *ngIf="onHowerChangeImage">
      <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">
        <img [src]="ImageSrc ? ImageSrc : product.images[1].src" class="img-fluid lazy-loading" alt="{{ product.images[1].alt }}">
      </a>
    </div>
    <ul class="product-thumb-list" *ngIf="thumbnail">
      <li class="grid_thumb_img" [class.active]="ImageSrc == image.src" *ngFor="let image of product.images">
        <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image.src)">
          <img [lazyLoad]="image.src">
        </a>
      </li>
    </ul>
    <div class="cart-info cart-wrap">
      <a href="javascript:void(0)" title="Add to cart" (click)="CartModal.openModal(product)"
        *ngIf="cartModal">
        <i class="ti-shopping-cart"></i>
      </a>
      <a href="javascript:void(0)" title="Add to cart" (click)="addToCart(product)"
        *ngIf="!cartModal">
        <i class="ti-shopping-cart"></i>
      </a>
      <a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product)">
        <i class="ti-heart" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
        <i class="ti-search" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Compare" (click)="addToCompare(product)">
        <i class="ti-reload" aria-hidden="true"></i>
      </a>
    </div>
  </div>
  <div class="product-detail">
    <div class="p-3">
      <!-- <bar-rating [rate]="5" [readOnly]="true"></bar-rating> -->
      <h6 class="mt-3">{{ product.description | titlecase }}</h6>
      <a [routerLink]="['/shop/product/left/sidebar/', product?.title.replace(' ', '-')]">
        <h5>{{ product?.title  }}</h5>
      </a>
      <h6  class="mt-2" *ngFor="let variant of product?.variants"
        (click)="ChangeVariants(variant.colour, product)">
        {{variant.colour}}</h6>
      
      <!-- <h6>{{product | json}}</h6> -->
      <!-- <h4>
        {{ product?.price * currency?.price | discount:product | currency:currency?.currency:'symbol' }}
        <del *ngIf="product?.discount"><span class="money"> {{ product?.price * currency?.price | currency:currency?.currency:'symbol' }}</span></del>
      </h4> -->
      <!-- <ul class="color-variant" *ngIf="Color(product?.variants).length">
        <li [class]="color" *ngFor="let color of Color(product?.variants)" [ngStyle]="{'background-color': color}"
          (click)="ChangeVariants(color, product)">
        </li>
      </ul> -->

      <!-- <ul class="color-variant">
        <li [class]="variant.colour" *ngFor="let variant of product?.variants" [ngStyle]="{'background-color': variant.colour}"
          (click)="ChangeVariants(variant.colour, product)">
          {{variant.colour}}
        </li>
      </ul> -->


    </div>
  </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>

