/**
 * Wild card search on all property of the object
 * @param {Number | String} input - any value to search
 * @param {Array} list - array for search
 * @return {Array} array of object contained keyword
 */
export function WildcardSearch<T>(list: T[], input: string | number): T[] {
    var i=0;
    const searchText = (item) => {
        for (let key in item) {
            if (item[key] == null) {
                continue;
            }
            if (item[key].toString().toUpperCase().indexOf(input.toString().toUpperCase()) !== -1) {
                return true;
            }
        }
    };
    list = list.filter(value => searchText(value));
    return list;
}

// export function fullTextSearch<T>(list: T[], inputText: string): T[] {
//     const searchText = (item, filter) => {
//         for (let key in item) {
//             if (Array.isArray(item[key]) && item[key].length == 0)continue;
//             if (item[key] == null) continue;
//             if (typeof item[key] === 'object') {
//                 filter = searchText(item[key], filter);
//             } else {
//                 var trovato = false;
//                 for(let searchEle of filter){
//                     if((item[key]+"").toLowerCase().includes(searchEle.value)){
//                         trovato = true;
//                         searchEle.find = true;
//                     }
//                 }
//                 if(trovato){
//                     filter = filter.filter(itemF => !itemF.find);
//                     if(filter.length == 0) return [];
//                 }
//             }
//         }
//         return filter
//     };
//     const v = inputText.split(' ').map(item => ({find: false, value: item.toLowerCase()}))
//     list = list.filter(value => searchText(value, v.map(item => ({...item}))).length == 0);
//     return list;
//     // function cercaProdotti(paroleChiave, prodotti) {
//     //     const paroleArray = paroleChiave.toLowerCase().split(' ');

//     //     return prodotti.filter(prodotto => {
//     //         const descrizione = prodotto.descrizione.toLowerCase();
//     //         return paroleArray.every(parola => descrizione.includes(parola));
//     //     });
//     // }
// }


export function fullTextSearch<T>(list: T[], inputText: string): T[] {
  // Funzione ricorsiva per cercare il testo all'interno di un oggetto
  const searchText = (item, filter) => {
      for (let key in item) {
          if (Array.isArray(item[key]) && item[key].length === 0) continue;
          if (item[key] === null) continue;

          // Se il valore è un oggetto, richiamare ricorsivamente
          if (typeof item[key] === 'object') {
              filter = searchText(item[key], filter);
          } else {
              // Controllare se la stringa contiene uno degli elementi di filtro
              let trovato = false;
              for (let searchEle of filter) {
                  if ((item[key] + "").toLowerCase().includes(searchEle.value)) {
                      trovato = true;
                      searchEle.find = true;  // Segnare l'elemento trovato
                  }
              }

              // Se abbiamo trovato tutti i termini di ricerca, fermiamo la ricerca
              if (trovato) {
                  filter = filter.filter(itemF => !itemF.find);  // Rimuovere gli elementi già trovati
                  if (filter.length === 0) return [];
              }
          }
      }
      return filter;
  };

  // Preprocessare l'input per dividere le parole chiave e metterle in lowercase
  const v = inputText.split(' ').map(item => ({ find: false, value: item.toLowerCase() }));

  // Filtrare la lista in base alla ricerca
  list = list.filter(value => searchText(value, v.map(item => ({ ...item }))).length === 0);

  return list;
}

class TrieNode {
    children;
    docIds;
    constructor() {
        this.children = {};
        this.docIds = new Set();
    }
}

export class Trie {
    root: TrieNode;
    constructor() {
        this.root = new TrieNode();
    }

    insert(word, docId) {
        for (let i = 0; i < word.length; i++) {
            let node = this.root;
            for (let j = i; j < word.length; j++) {
                const char = word[j];
                if (!node.children[char]) {
                    node.children[char] = new TrieNode();
                }
                node = node.children[char];
                node.docIds.add(docId);
            }
        }
    }
    makerow(objectItem){
        var allWord = new Set();
        for (let key in objectItem) {
            if (objectItem[key] == null) continue;
            if (Array.isArray(objectItem[key]) && objectItem[key].length == 0)continue;
            if (typeof objectItem[key] === 'object') {
                allWord = new Set([...this.makerow(objectItem[key]), ...allWord]);
            } else {
                if(typeof objectItem[key] === "number"){
                    allWord.add(objectItem[key] + "");
                }
                else if(typeof objectItem[key] === "string" && objectItem[key].length > 0){
                    allWord.add(objectItem[key])
                }
            }
        }
        return allWord;
    }
    init(objectList){
        for(let item in objectList.slice(0,1000)){
            this.insert([...this.makerow(objectList[item])].join(' '), item);
        }
    }

    searchSubstring(substring) {
        const words = substring.split(" ");
        let node = this.root;
        for(let word of words){
            for (const char of word) {
                if (!node.children[char]) {
                    return new Set();
                }
                node = node.children[char];
            }
        }
        return node.docIds;
    }
}
