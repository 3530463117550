<ng-template class="theme-modal" #imagePreview let-modal>
    <div class="modal-content quick-view-modal">
      <div class="modal-body">
        <button type="button" class="close float-end border-0" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
        <div id="carouselExampleControls" class="carousel slide">
          <div class="carousel-inner">
            <div *ngFor="let image of product.images; let i = index" 
                 [ngClass]="{'carousel-item': true, 'active': i === imageIndex}">
              <img [src]="image.src" [alt]="image.alt" class="d-block w-100">
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev" (click)="previewImage()">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next" (click)="nextImage()">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
</ng-template>
