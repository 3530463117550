import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { Product } from '../classes/product';

@Injectable({
  providedIn: 'root'
})
export class IndexedDBService extends Dexie {
  products: Dexie.Table<Product, number>;

  constructor() {
    super('ProductDatabase');
    this.version(1).stores({
      products: 'id' // Indice basato su 'id' del prodotto
    });
    this.products = this.table('products');
  }

  // Metodi per aggiungere, ottenere e svuotare prodotti
  async addProducts(products: Product[]): Promise<void> {
    await this.products.bulkPut(products);
  }

  async getProducts(): Promise<Product[]> {
    return await this.products.toArray();
  }

  async clearProducts(): Promise<void> {
    await this.products.clear();
  }
}