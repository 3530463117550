<!-- Start Categories List -->
<div class="collection-collapse-block border-0" [class.open]="collapse" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseOut()">
  <h4 class="collapse-block-title" (click)="collapse = !collapse">Class</h4>
  <div class="collection-collapse-block-content">
    <div>
      <div class="collection-brand-filter">
        <ul class="category-list">
          @for (category of filterbyCategory; track $index) {
            <li *ngIf="category && category !== ''">
              <i class="fa fa-check" *ngIf="categorySelect === category"></i>
              <a (click)="onClick(category)">
                {{ category }}
              </a>
            </li>
          }
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- End Categories List -->
